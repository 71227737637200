

interface CheckPlanType  {
  id: string;
  bu: string;
  checkingRoundId: string;
  checkingRound: string;
  batchCount: string;
  programCount: string;
  lastOperationTime: string;
  operationUser: string;
  statusText: string;
}
interface CheckingRoundType {
  key: string;
  value: string;
}

import {  ref, onMounted ,reactive, toRefs,defineComponent  } from "vue";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import CheckingRound from "./CheckingRound/index.vue"
import UploadCheckPlan from "./UploadCheckingPlan/index.vue"
import { getCheckingRoundDropList,getCheckingRoundTable ,downloadDetail,getCheckingRoundStatus ,checkingRoundSubmit ,deleteCheckingPlan} from "@/API/checking/checkingPlan";
import { getUser } from "@/API/claim/delaerClaim/delaer";
import { message ,Modal } from "ant-design-vue";
export default defineComponent({
    components:{
      CheckingRound,UploadCheckPlan
    },
    setup() {
      //抽屉实例
      const checkingRoundDom = ref<any>(null);
      const uploadDom =ref<any>(null);
      //查询框数据
      const searchData = reactive<any>({
        checkingRoundVal: null, 
        BUVal:null, 
        statusVal:null
      });
      const buArray = ref<any[]>([]);
      //CheckingRound的数据，从后端获取
      const checkingRoundArray = ref<CheckingRoundType[]>([]);
      //CheckingRound的status数据，从后端获取
      const checkingRoundStatusArray = ref<CheckingRoundType[]>([]);
      //分页
      const pagination = reactive({
        total:0,
        currentPage:1,
        pageSize:20
      })

      //表格

      const dataSource = ref<CheckPlanType[]>([]);

      // {
      //   checkingRound:'2021 Mar',
      //   bu:'MB',
      //   programCount:320,
      //   batchCount:8,
      //   lastOperationTime:'2021/01/01 12:00:00',
      //   operationUser:'WELELE',
      //   statusText:'SIPO Approved'
      // }
       const columns =[
          {
            title: 'Checking Round',
            dataIndex: 'checkingRound',
            key: 'checkingRound',
            width:120,
          },
          {
            title: 'BU',
            dataIndex: 'bu',
            key: 'bu',
            width:80,
          },
          {
            title: 'Program Count',
            dataIndex: 'programCount',
            key: 'programCount',
            width:140,
          },
          {
            title: 'Batch Count',
            dataIndex: 'batchCount',
            key: 'batchCount',
            width:120,
          },
          {
            title: 'Last Operation Time',
            dataIndex: 'lastOperationTime',
            key: 'lastOperationTime',
            width:170,
          },
          {
            title: 'Operation User',
            dataIndex: 'operationUser',
            key: 'operationUser',
            width:120,
          },
          {
            title: 'Status',
            dataIndex: 'statusText',
            key: 'statusText',
            width:120,
          },
          {
            title: 'Operation',
            dataIndex: 'operation',
            slots: { customRender: 'operation' },
            width:120,
          },
        ]
      //表格高度
      const tableHeight = window.innerHeight - 430;
      //表格类型
      type Key = ColumnProps['key'];

      const state = reactive<{
        selectedRowKeys: Key[];
        loading: boolean;
      }>({
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
      });
      

      //表格事件
      const onSelectChange = (selectedRowKeys: Key[]) => {
        state.selectedRowKeys = selectedRowKeys;
      };

      //导出表格
      const exportTable = (record: any)=>{
        downloadDetail({planId:record.id});
      }

      const uploadVisable = ref(false);
      //显示CheckingRound的抽屉
      const showCheckingRound =()=>{
        //
        checkingRoundDom.value.showDrawer();
      }

      //显示上传页面
      const showUpload =()=>{
        uploadDom.value.showDrawer(buArray.value);
      }

      //获取CheckingRound
      const getCheckingRound = ()=>{
        // getCheckingRoundDropList
        getCheckingRoundDropList().then((res) => {
          checkingRoundArray.value = res
        })
      }

      //获取表格status
      const getStatus =  ()=>{

        
        getCheckingRoundStatus({groupCode:'checking_plan_status'}).then((res) => {
          // checkingRoundStatusArray
          for (let i=0;i<res.length;i++){
            checkingRoundStatusArray.value.push({
              key:res[i].itemCode,
              value:res[i].itemNameEn
            })
          }
        })
      }

      const searchParams = reactive<any>({
        checkingRoundVal: null, 
        BUVal:null, 
        statusVal:null
      });



      //获取表格数据
      const getTableData = ()=>{
        
        const params = {
          current:pagination.currentPage-1,
          size:pagination.pageSize,
          bu:searchParams.BUVal,
          checkingRoundId:searchParams.checkingRoundVal,
          statuses:[searchParams.statusVal]
        }

        console.log(params.bu)
        if ( searchParams.BUVal === null ||  searchParams.BUVal === "" ||  searchParams.BUVal === undefined){
          let str = "";
          for (let i=0;i<buArray.value.length;i++){
            str+= buArray.value[i].nameEn +','
          }

          str = str.substr(0, str.length - 1);
          params.bu = str;
        }

        getCheckingRoundTable(params).then((res) => {
          pagination.total = parseInt(res.totalNum);
          dataSource.value = []
          for (let i=0;i<res.data.length;i++){
            const one = res.data[i];

            dataSource.value.push({
                id: one.id,
                bu: one.bu,
                checkingRoundId: one.checkingRoundId,
                checkingRound: one.checkingRound,
                batchCount: one.batchCount,
                programCount: one.programCount,
                lastOperationTime: one.lastOperationTime,
                operationUser: one.operationUser,
                statusText: one.statusText
            })
          }
          state.selectedRowKeys = [];
        })
      }

      const searchTable = ()=>{
        pagination.currentPage = 1;
        searchParams.checkingRoundVal = searchData.checkingRoundVal;
        searchParams.BUVal = searchData.BUVal;
        searchParams.statusVal =searchData.statusVal;
        getTableData();
      }

      //提交checkingPlan
      const submitCheckingPlan = () =>{
        if (state.selectedRowKeys.length === 0){
          message.error('Please select one and click again!')
          return;
        }

        Modal.confirm({
          title: 'Please confirm',
          content: 'Do you confirm to submit current checking plan?',
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk() {
            checkingRoundSubmit(state.selectedRowKeys).then((res) => {
              if (res.code === "0"){
                message.success(res.message),
                getTableData();
              } else {
                message.error(res.message)
              }
            })
          },
          onCancel() {
            console.log('Cancel');
          },
        });

      }
      
      //删除表格
      const deleteTable = (record: any)=>{

        if (record.statusText === "Submitted" || record.statusText === "SIPO Approved"){
          message.error(record.statusText +" status cannot be deleted");
          return
        }

        Modal.confirm({
          title: 'Please confirm',
          content: 'Do you confirm to delete current checking plan?',
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk() {
            deleteCheckingPlan([record.id]).then((res) => {
              if (res.code === "0"){
                message.success(res.message),
                pagination.currentPage = 1;
                getTableData();
                getCheckingRound();
              } else {
                message.error(res.message)
              }
            })
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }

      const refershTableAndSelect= ()=>{
        getTableData();
        getCheckingRound();
      }

      //更改分页页签
      const pageChange = (page: number)=>{
        pagination.currentPage = page;
        state.selectedRowKeys = [];
        getTableData();
      }
      //改变每页大小
      const sizeChange = (page: number,pageSize: number)=>{
        pagination.pageSize = pageSize;
        pagination.currentPage = 1;
        state.selectedRowKeys = [];
        getTableData();
      }

      //重新设置筛选信息
      const resetTableData = ()=>{
        searchData.checkingRoundVal = null;
        searchData.BUVal = null;
        searchData.statusVal = null;
        searchParams.checkingRoundVal = null;
        searchParams.BUVal = null;
        searchParams.statusVal = null;
      }



      

      onMounted(() => {
          
          getCheckingRound();
          getStatus();
          // getUser();
          getUser().then((res)=>{
            buArray.value= res.buList.filter((item: any) =>{
              return item.parentId === null
            })
            getTableData()
          })
      })

      return {
        refershTableAndSelect,
        searchTable,
        resetTableData,
        submitCheckingPlan,
        checkingRoundStatusArray,
        uploadDom,
        uploadVisable,
        checkingRoundDom,
        showCheckingRound,
        sizeChange,
        pageChange,
        exportTable,
        deleteTable,
        ...toRefs(state),
        onSelectChange,
        tableHeight,
        dataSource,columns,
        ...toRefs(pagination),
        ...toRefs(searchData),
        checkingRoundArray,
        showUpload,
        getTableData,
        buArray
      }
    }
})
