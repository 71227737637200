
interface CheckingRoundType {
  key: string;
  value: string;
  text: string;
}
import { ref, onMounted,createVNode , reactive, toRefs, defineComponent } from "vue";
import downloadFileUrl from "@/utils/claim/downloadFileUrl";
import { FileItem } from '@/API/types';
import { message ,Modal } from 'ant-design-vue';
import axios from "axios";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCheckingPlanDropList ,downloadTemplate ,uploadFileCheckingPlan } from "@/API/checking/checkingPlan";
export default defineComponent({
  emits: ["change-table"],
//   
  props: {
    buArray: {
      required: true,
    },
  },
  components:{
      ExclamationCircleOutlined
  },
  setup(props, ctx) {
    const formData = new FormData();
    const visible = ref(false);
    //两个下拉框的值
    const checkingRound = ref<null|string>(null);
    const businessUnit = ref<null|string>(null);
    const isDisabled = ref(true);

    const isBeforeUpload = ref<boolean>(true)
    // 进度条
    const isShowUploadBtn = ref<boolean>(true)
    const isClickAbled = ref<boolean>(true)

    const curUploadFile = ref<object>({});
    const curPercent = ref<number>(0)
    const uploadFileSize = ref<string>('')
    const completeTips = ref<boolean>(false)

    const TipsText = ref('');
    const MessageModalVisible = ref(false);

    //关闭抽屉事件
    const closeThisDrawer = () => {
      visible.value = false;
    };
    const buss = ref<any[]>([]);
    //显示抽屉
    const showDrawer = (bu: Array<any>[]) => {
        buss.value = bu;
      visible.value = true;
    };
    //重传
    const handelBrowse= () =>{
        curPercent.value = 0;
        uploadFileSize.value = "";
        completeTips.value = false;
        isClickAbled.value = true;
        isShowUploadBtn.value = true;
        curUploadFile.value = {};
        isBeforeUpload.value = true;
        if (formData.has('file')){
            formData.delete('file')
        }
    }

    //关闭抽屉的回调
    const afterVisibleChange = () => {
        businessUnit.value = null;
        checkingRound.value = null;
        curPercent.value = 0;
        uploadFileSize.value = "";
        completeTips.value = false;
        isClickAbled.value = true;
        isShowUploadBtn.value = true;
        curUploadFile.value = {};
        isBeforeUpload.value = true;
        isDisabled.value = true;
        if (formData.has('file')){
            formData.delete('file')
        }
    };
    const loading = ref<boolean>(false);
    //CheckingRound的status数据，从后端获取
    const checkingRoundStatusArray = ref<CheckingRoundType[]>([]);

    //处理bussiness的选择，之后让checkinground可选
    const handleChange= (value: string)=>{
        if (value === null || value.length === 0){
            return
        }
        isDisabled.value = true;
        checkingRound.value = null;
        
        getCheckingPlanDropList([value]).then((res) => {
          checkingRoundStatusArray.value = res;
          isDisabled.value = false;
        })
    }

    //下载模板
    const downloadTemHandle = ()=>{
        if (businessUnit.value === null || checkingRound.value === null || checkingRound.value === undefined || businessUnit.value.length<1 || checkingRound.value.length<1){
            message.error('Please choose checking round')
            return
        }

        downloadTemplate({roundId:checkingRound.value});
    }
     // 上传前
    const beforeUpload = (file: FileItem) => {
        if (businessUnit.value === null || checkingRound.value === null){
            message.error('Please choose checking round and BusinessUnit')
            return false;
        }
        const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isXlsx) {
            message.error('Only xlsx format files can be uploaded!')
        }
        const isLt5M = file.size / 1024 / 1024 < 10
        if (!isLt5M) {
            message.error('The file size should not be larger than 10MB!')
        }
        return isXlsx && isLt5M
    }



    const uploadRequest = (source: any) => {
        if (formData.has('file')){
            formData.delete('file')
        }
        formData.append('file', source.file);
        curUploadFile.value = source.file;
        uploadFileSize.value = source.file.size / 1024 > 1024 ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB` : `${Math.floor(source.file.size / 1024)} KB`
        isBeforeUpload.value = false
    }

    //错误告警窗口
    const downloadModalVisible = ref(false);
    const okButton = ref(false);
    const downloadTipsText = ref<string>('')
        //上传报错的取消按钮
    const handleCancel = ()=>{
        downloadModalVisible.value = false;
    }
            // 上传文件
    const uploadFile = (source: any) => {
        let buName = "";
        for (let i=0;i<buss.value.length;i++){
            if (buss.value[i].id === businessUnit.value){
                buName = buss.value[i].nameEn;
            }
        }

        isClickAbled.value = false
        const params = {
            bu: buName ,
            buId:businessUnit.value,
            checkingRoundId:checkingRound.value,
            file: formData
        }

        axios({
            method: "post",
            data: formData,
            url: "/claimapi/checking/plan/detail/sicco/upload",
            params: params,
            // responseType: "blob",
        }).then((res) => {
            const data = res.data;
            isShowUploadBtn.value = false
            curPercent.value = 100
            completeTips.value = true
            isClickAbled.value = true
            if (data.code === "0"){
                message.success('Upload succesfully')
                ctx.emit('change-table')
                
            } else if (data.code === "50110" || data.code === "50095") {
                // downloadModalVisible.value = true;
                // okButton.value = true;
                // downloadTipsText.value = res.data.message

                TipsText.value = res.data.message;
                MessageModalVisible.value = true;

                // Modal.warning({
                //     title: 'Tip',
                //     icon: createVNode(ExclamationCircleOutlined),
                //     content: res.data.message,
                //     okText: 'Close',
                // });

                // message.error(res.data.message)
            } else {
                downloadModalVisible.value = true;
                okButton.value = false;
                downloadTipsText.value = 'Data validation unsuccessful, please check and reupload.'
                // 存data里的信息
                if (res.data !== ''){

                    window.sessionStorage.setItem(
                        'errorFileName',
                        res.data.data
                    );
                    // debugger
                    // const blob = new Blob([res.data], { type: 'application/vnd-excel; char-set=UTF-8' }) //指定格式为vnd.ms-excel
                    // const downloadElement = document.createElement('a')
                    // const href = window.URL.createObjectURL(blob) //创建下载的链接
                    // downloadElement.href = href
                    // const str = res.headers['content-disposition']
                    // const index=str.lastIndexOf("=");

                    // console.log(res.headers['content-disposition'])
                    // debugger
                    // if (str.substring(index+1,str.length)) {
                    //     downloadElement.download = decodeURI(str.substring(index+1,str.length))
                    // } 
                    // else if (fileName) {
                    //     downloadElement.download = fileName
                    // } else {
                    //     downloadElement.download = Date.now().toString()
                    // }
                    // document.body.appendChild(downloadElement)
                    // downloadElement.click() //点击下载
                    // document.body.removeChild(downloadElement) //下载完成移除元素
                    // window.URL.revokeObjectURL(href) //释放掉blob对象
                    }
                 }
        })
    }

        // 上传功能出错后下载模态窗口
    const handleDownload = () =>{
        loading.value = true;
        // 调下载错误文件接口
        const path = window.sessionStorage.getItem('errorFileName')
            ? window.sessionStorage.getItem('errorFileName')
            : null;
        if (!path) return;
        const downloadParams = {
            // payoutRoundId: roundVal.value,
            
            path: window.sessionStorage.getItem('errorFileName'),
        };
        const params = {
            url: `/claimapi/common/download/errorFile`,
            method: 'get',
            params: downloadParams,
            fileName:path.split('claim/plan/')[1],
            // fileName: `${+new Date()}.xlsx`,
        };
        // url, method = 'get', params, fileName
        downloadFileUrl(
            params,
            'application/vnd-excel; char-set=UTF-8',
        ).then((res) => {
            console.log(res)
            loading.value = false;
            downloadModalVisible.value = false;
            
            // emptyVisible.value = false;
        });
    }

    //ok按钮，刷新表格
    const freshTable = ()=>{
        //刷新表格
        // ctx.emit('change-table')
        visible.value = false;
    }
    return {uploadFile,downloadModalVisible,downloadTipsText,handleDownload,loading,handleCancel,handelBrowse,freshTable,
            isShowUploadBtn,curUploadFile,curPercent,uploadFileSize,completeTips,isDisabled,checkingRoundStatusArray,isClickAbled,
        checkingRound,businessUnit,isBeforeUpload,beforeUpload,uploadRequest,
      visible,afterVisibleChange,handleChange,okButton,
      closeThisDrawer,showDrawer,downloadTemHandle,TipsText,MessageModalVisible,
    };
  },
});
