import { fetchGet,fetchDelete ,fetchPost,fetchGetMess ,fetchPostPic} from '../index';
import downloadFile from "@/utils/claim/downloadFile";
import axios from 'axios'
//获取checkingRound的列表
export const getCheckingRoundList= (): Promise<any> => {
    return fetchGet('/claimapi/checking/round/list')
}

//查看submission_date的列表
export const getCheckingRoundView= (params = {roundId:''}): Promise<any> => {
    return fetchGet('/claimapi/checking/round/view',{params})
}

//生成checkround
export const generateCheckingRound = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchPost('/claimapi/checking/round/generate', params)
}


//检查checkinground是否存在
export const checkCheckingRound= (params = {}): Promise<any> => {
    return fetchGetMess('/claimapi/checking/round/check',{params})
}

//删除checkingRound
export const deleteCheckingRound= (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchDelete('/claimapi/checking/round/delete', {params})
}

//checkingPlan的提交
export const checkingRoundSubmit = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchPostPic('/claimapi/checking/plan/supervisor/submit', params)
}

//approveCheckingPlan的Reject
export const checkingRoundReject = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchPostPic('/claimapi/checking/plan/sales/reject', params)
}

//approveCheckingPlan的Approve
export const checkingRoundArrpove = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchPostPic('/claimapi/checking/plan/sales/approve', params)
}


//删除checkingPlan
export const deleteCheckingPlan = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchPostPic('/claimapi/checking/plan/supervisor/delete', params)
}


//获取Checking Plan的主数据列表 4942

export const getCheckingRoundTable= (params = {}): Promise<any> => {
    return fetchGet('/claimapi/checking/plan/supervisor/page',{params})
}

//获取Checking Plan的 checking round 4942
export const getCheckingRoundDropList= (params = {}): Promise<any> => {
    return fetchGet('/claimapi/checking/plan/checking/round/dropList',{params})
}

//获取Checking Plan的 checking round 4947
export const getCheckingRoundDropListNoSave= (params = {}): Promise<any> => {
    return fetchGet('/claimapi/checking/plan/checking/round/dropList/nosave',{params})
}

//获取Checking Plan的 statue 4942
export const getCheckingRoundStatus= (params = {}): Promise<any> => {
    return fetchGet('/claimapi/claimDictionary/findByGroupCode',{params})
}
//获取Checking Plan Approve的主数据列表 4947
export const getCheckingRoundApproveTable= (params = {}): Promise<any> => {
    return fetchGet('/claimapi/checking/plan/sales/page',{params})
}

//获取checkingRound 4946
export const getCheckingPlanDropList = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchPost('/claimapi/checking/plan/detail/sicco/checkingPlan/dropList', params)
}

//上传文件checkingplan 4946
export const uploadFileCheckingPlan = (params = {}, data: FormData): Promise<any> => {
    return fetchPostPic('/claimapi/checking/plan/detail/sicco/upload',data, params)
}
//导出明细
export const downloadDetail = (params = {}): void => {
    const option = {
        url: `/claimapi/checking/plan/export/detail`,
        method: 'get',
        params
    }
    downloadFile(option, 'application/vnd-excel; char-set=UTF-8')
}


//导出模板 4946
export const downloadTemplate = (params = {}): void => {
    const option = {
        url: `/claimapi/checking/plan/export/template`,
        method: 'get',
        params
    }
    downloadFile(option, 'application/vnd-excel; char-set=UTF-8')
}




// export const uploadFileCheckingPlan = (params = {},formData:FormData) => {
//     return axios({
//         method: 'post',
//         data: formData,
//         url: '/claimapi/payout/reimbursement/uploadReimbursement',
//         params: params
//     })
// }





