
import {  ref, onMounted ,reactive, toRefs,defineComponent } from "vue";
import axios from 'axios'
import moment from 'moment'
import { Moment } from 'moment';
import { message ,Modal} from "ant-design-vue";
import { getCheckingRoundList ,checkCheckingRound,deleteCheckingRound,generateCheckingRound,getCheckingRoundView } from "@/API/checking/checkingPlan";

interface CheckingRoundType  {
  id: string;
  roundName: string;
}

interface BatchDateType {
  batchCount: string;
  submissionDate: Moment|null;
}

const switchMonth =(month: string)=>{
  let rm = "1";
  switch (month) {
    case 'Jan':
      rm = "1";
      break;
    case 'Feb':
      rm = "2";
      break;
    case 'Mar':
      rm = "3";
      break;
    case 'Apr':
      rm = "4";
      break;
    case 'May':
      rm = "5";
      break;
    case 'Jun':
      rm = "6";
      break;
    case 'Jul':
      rm = "7";
      break;
    case 'Aug':
      rm = "8";
      break;
    case 'Sep':
      rm = "9";
      break;
    case 'Oct':
      rm = "10";
      break;
    case 'Nov':
      rm = "11";
      break;
    case 'Dec':
      rm = "12";
      break;
  } 
  return rm
}

export default defineComponent({
    setup(prop, ctx) {

      const visible = ref(false);
      const modalVisible = ref(false);

      //向后端发送请求的时候，让按钮不可点
      const isGenerate = ref(false);

      //关闭抽屉事件
      const closeThisDrawer = () => {
        visible.value = false;
      };

      //下拉框的选项
      const year = ref<string|null>(null);
      const yearArray: Array<string> = [];
      for (let i=2;i>=0;i--){
        yearArray.push(moment().subtract(i, 'year').format('YYYY'));
      }
      for (let i=1;i<=20;i++){
        yearArray.push(moment().add(i, 'year').format('YYYY'));
      }
      const month = ref<string|null>(null);
      const monthArray = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      const batchCount = ref<number|null>(null);
      const batchCountArray: Array<number> = []
      for (let i=1;i<=20;i++){
        batchCountArray.push(i);
      }
      //table
       const columns =[
          {
            title: 'Checking Round',
            dataIndex: 'roundName',
            key: 'roundName',
          },
          {
            title: 'Operation',
            dataIndex: 'operation',
            slots: { customRender: 'operation' },
          },
      ]
      const dataSource = ref<CheckingRoundType[]>([]);

      //弹出框表格
      const modalColumns = [
        {
          title: 'Batch Count',
          dataIndex: 'batchCount',
          key: 'batchCount',
        },
        {
          title: 'Submission Date',
          dataIndex: 'submissionDate',
          slots: { customRender: 'submissionDate' },
        },
      ];
      const modalDataSource = ref<BatchDateType[]>([]);

      //限定时间不能超过今天
      const disabledStartDate = (startValue: Moment) => {
        // if (!startValue || !endValue.value) {
        //   return false;
        // }

        return startValue.valueOf() <= moment().add(-1, 'days').valueOf();
      };

      // //用于临时存储选择的时间
      // const temporaryDate = ref<Moment|null>(null);
      // const temporaryCheckingRound = ref<string>("");

      //校验是否已经被导入
      const checkCheckRound=(code: string)=>{
        let sign = 0;
        switch (code) {
            case "50078":
                message.error('Checking round is exists');
                sign = 2;
                break;
            case "50077":
                //message.info('Current checking round already exist');
                sign = 1;
                break;
            case "50079":
                message.info('Submission Date squence not consistenct with batch');
                sign = 2;
                break;
            case "50080":
                message.info('Current round has checking plan, cannot be deleted');
                sign = 2;
                break;
            case "50071":
                message.info('Checking plan has been delete');
                sign = 2;
                break;
        } 
        return sign
      }

      //控制Modal的图标，是否是查看
      const isView = ref(true);

      //显示Modal
      const showModal =(batchCountArray: any)=>{
        if (batchCountArray !== null){
          modalDataSource.value = [];    
          for (let i=0;i<batchCountArray.length;i++){
            modalDataSource.value.push({
              batchCount:"Batch "+(i+1)+":",
              submissionDate:moment(batchCountArray[i])
            })
          }
          modalVisible.value = true;
          isView.value = true;
        } else {
          modalDataSource.value = [];
          const count = batchCount.value as number;
          for (let i=1;i<=count;i++){
            modalDataSource.value.push({
              batchCount:"Batch "+i+":",
              submissionDate:null
            })
          }
          isView.value = false;
          modalVisible.value = true;
        }

      }

      //添加一条CheckingRound
      const addCheckingRound = ()=>{
        if (null === year.value || null===month.value || null===batchCount.value){
          message.error('Please select round information firstly')
          return
        }
        checkCheckingRound({year:year.value,month:switchMonth((month.value as string))}).then((res: any): void => {
          const sign = checkCheckRound(res.code);
          if (sign === 1){
            Modal.confirm({
              title: 'Please confirm',
              content: 'Current checking round already exist',
              okText: 'Continue',
              cancelText: 'Cannel',
              onOk() {
                showModal(null);
              },
              onCancel() {
                console.log('Cancel');
              },
              class: 'test',
            });
          } else if (sign === 0){
            showModal(null);
          }
        }).catch((err: any): void => {
          console.log(err)
        })
      }

      //表格高度
      const tableHeight = 380;

      //添加数据并刷新
      const addAndFresh=(param: any)=>{

          generateCheckingRound(param).then((res: any): void => {
            getCheckingRoundList().then((res: any): void => {
              dataSource.value = []
              for (let i=0;i<res.length;i++){
                dataSource.value.push({
                  id:res[i].id,
                  roundName:res[i].roundName
                })
              }
            message.success('success');
            modalVisible.value = false;
            isGenerate.value = false;
            })
          })
      }

      //生成Batch
      const generateBatch = ()=>{
        //对未填写数据进行校验
        for (let i=0;i<modalDataSource.value.length;i++){
          if (modalDataSource.value[i].submissionDate === null){
            message.error("Please select submission date")
            return
          }
        }
        //对事件从小到大进行校验
        for (let i=0;i<modalDataSource.value.length-1;i++){
          const a  =(modalDataSource.value[i].submissionDate as Moment).unix()
          const b = (modalDataSource.value[i+1].submissionDate as Moment).unix()
          if ((modalDataSource.value[i].submissionDate as Moment).format('YYYY-MM-DD') === (modalDataSource.value[i+1].submissionDate as Moment).format('YYYY-MM-DD')){
             message.error("Submission Date squence not consistenct with batch")
             return
          }

          if (a-b>0){
             message.error("Submission Date squence not consistenct with batch")
             return
          }
        }

        //生成数组
        const dayArray: Array<string> = [];
        for (let i=0;i<modalDataSource.value.length;i++){
          dayArray.push((modalDataSource.value[i].submissionDate as Moment).format('M/D/YYYY'))
        }

        isGenerate.value = true;
        //对数据进行校验
        checkCheckingRound({year:year.value,month:switchMonth((month.value as string))}).then((res: any): void => {
          const sign = checkCheckRound(res.code);
          const param = {
            batchCount:batchCount.value,
            month:parseInt(switchMonth((month.value as string))),
            year:parseInt((year.value as string)),
            submissionDateList:dayArray
          }
          // if (sign === 1){
          //   Modal.confirm({
          //     title: 'Please confirm',
          //     content: 'Current checking round already exist',
          //     okText: 'Continue',
          //     cancelText: 'Cannel',
          //     onOk() {
          //       addAndFresh(param);
          //     },
          //     onCancel() {
          //       console.log('Cancel');
          //     },
          //     class: 'test',
          //   });
          // } else 
          if (sign === 0 || sign === 1){
            addAndFresh(param);
          } else {
             isGenerate.value = false;
          }

        })
      
      }

      //删除checkround
      const deleteTable = (record: CheckingRoundType)=>{
        const config: any = {
            url: '/claimapi/checking/round/delete',
            method:'delete',
            params: {roundId:record.id},
        };
        Modal.confirm({
          title: 'Please confirm',
          content: 'Are you sure to delete?',
          okText: 'Delete',
          cancelText: 'Cannel',
          onOk() {
            axios(config).then(res => {

              if (res.data.code === "50071"){
                message.error(res.data.message)
              } else
              if (res.data.code === "50080"){
                message.error(res.data.message)
              } else
              if (res.data.code === "0"){

                getCheckingRoundList().then((res: any): void => {
                  dataSource.value = []
                  for (let i=0;i<res.length;i++){
                    dataSource.value.push({
                      id:res[i].id,
                      roundName:res[i].roundName
                    })
                  }
                  message.success('Successfully deleted')
                })
              } else {
                message.info('Unknown error')
              }
            }).catch(error => {
              // reject(error)
              console.log(error)
            })
          }
        });
      }

      //显示抽屉
      const showDrawer = ()=>{
        getCheckingRoundList().then((res: any): void => {
          visible.value = true;
          dataSource.value = []
          for (let i=0;i<res.length;i++){
            dataSource.value.push({
              id:res[i].id,
              roundName:res[i].roundName
            })
          }
          
        })
        
      }

       //关闭抽屉的回调
      const afterVisibleChange = () => {
        year.value = null;
        month.value = null;
        batchCount.value = null;
      };

      const selectCheckTime = ref("");

      //查看checking Round
      const viewTable = (record: CheckingRoundType)=>{
        selectCheckTime.value = record.roundName;
        getCheckingRoundView({roundId:record.id}).then((res: any): void => {

          showModal(res);
        })
      }

      return {
        year,yearArray,month,monthArray,batchCount,batchCountArray,columns,dataSource,tableHeight,modalVisible,
        modalColumns,modalDataSource,isView,isGenerate,selectCheckTime,
        visible,closeThisDrawer,afterVisibleChange,showDrawer,addCheckingRound,generateBatch,disabledStartDate,
        viewTable,deleteTable
      }
    }

})
