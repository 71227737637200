/*
 * @Author: zhengda.lv
 * @Date: 2021-10-30 10:15:26
 * @LastEditTime: 2021-12-17 16:13:15
 * @LastEditors: zhengda.lv
 * @Description: 
 */
import axios from 'axios';
import { AxiosRequestConfig } from '@/API/types';
export default (options: AxiosRequestConfig, type: string) => {
    const { url, method = 'get', params, fileName } = options;
    const config: any = {
        url: url,
        method,
    };
    if (method === 'get') {
        config['params'] = params;
    } else {
        config['data'] = params;
    }
    return new Promise((resolve, reject) => {
        axios(config)
            .then((res: any) => {
                // debugger
                // 文件格式
                // const blob = new Blob([res.data], { type: type }); //指定格式为vnd.ms-excel
                const downloadElement = document.createElement('a');
                const href = res.data.data; //创建下载的链接
                downloadElement.href = href;
                const str = res.headers['content-disposition'];
                // const index = str.lastIndexOf('=');
                // if (str.substring(index + 1, str.length)) {
                //     downloadElement.download = decodeURIComponent(
                //         str.substring(index + 1, str.length)
                //     );
                // } else if (fileName) {
                //     downloadElement.download = fileName;
                // } else {
                //     downloadElement.download = Date.now().toString();
                // }
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
                resolve(res);
            })
            .catch(function(error: string) {
                console.log(error);
                return reject(error);
            });
    });
};
